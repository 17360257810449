import React from 'react';
import CategoryCards from '../components/CategoryCards';
import { useTranslation } from 'react-i18next';
const HomePageRight = () => {
  const { t } = useTranslation();

  // Render the fetched data
  return (
    <div className="column column-right">
    <div
          className="titleOverP"
          style={{
            background: '#188900', 
            marginBottom: '10px', 
            color:'white',
            fontWeight: 'bold',
            padding: '5px', 
            paddingLeft: '15px', 
            textAlign: 'left', 
            textTransform: 'uppercase',
          }}>
           {t('machineryList')}
      </div>
    <CategoryCards/>
      </div> 
  );
};

export default HomePageRight;







