import React, { useState } from 'react';
import { Container, TextField, Button, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { useTranslation, Trans } from 'react-i18next';
import HomePageLeft from '../components/HomePageLeft';
import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';
import configDetails from './../components/utils/ConfigDetails';

const Contact = () => {
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    subject: '',
    name: '',
    email: '',
    phoneNumber: '',
    checkbox1: false,
    checkbox2: false,
  });

  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    try {
      const response = await fetch('https://quiagromaquinaria.com/apiiiwebsite/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      console.log('Form submitted successfully:', data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
    }
  };

  return (
    <Container maxWidth={false} style={{ marginTop: '70px', paddingLeft: '0px' }}>
      <div className="two-column-layout paddingTop10">
        <div className="column-left">
          <HomePageLeft />
        </div>
        <div className="column-right">
          <div className="vehicle-details">
            <div className="vehicle-specifications">{t('ContactPage')}</div>
            <div>
              <p style={{ padding: '10px' }}>
                
                Telephone: {configDetails.spain} <br />
                Email: {configDetails.email}<br />
              </p>
            </div>
          </div>

          <div className="vehicle-details">
            <div className="vehicle-specifications">{t('ContactPage')} form</div>
            <div>
              {!submitted ? (
                <form onSubmit={handleSubmit} style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      id="subject"
                      label={t('Subject')}
                      variant="outlined"
                      required
                      value={formData.subject}
                      onChange={handleChange}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      id="name"
                      label={t('Name')}
                      variant="outlined"
                      required
                      value={formData.name}
                      onChange={handleChange}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      id="email"
                      label={t('Email')}
                      variant="outlined"
                      required
                      value={formData.email}
                      onChange={handleChange}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <TextField
                      fullWidth
                      id="phoneNumber"
                      label={t('PhoneNumber')}
                      variant="outlined"
                      required
                      value={formData.phoneNumber}
                      onChange={handleChange}
                      style={{ marginBottom: '10px' }}
                    />
                  </div>
                  <div style={{ width: '50%' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="checkbox1"
                          checked={formData.checkbox1}
                          onChange={handleChange}
                        />
                      }
                      label={t('I have read, understood and accept the Privacy Policy')}
                      sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px' } }}
                    />
                  </div>
                  <div style={{ width: '50%'}} >
                    <FormControlLabel 
                      control={
                        <Checkbox
                          id="checkbox2"
                          checked={formData.checkbox2}
                          onChange={handleChange}
                          
                        />
                      }
                      label={t('I give my express consent to receive commercial communications from BFMB AB by email, WhatsApp and other means of digital communication, as established in the LSSI/CE 34/2002 law.')}
                      sx={{ '& .MuiFormControlLabel-label': { fontSize: '13px' } }}
                    />
                  </div>
                  <div style={{ width: '45%', padding:'10px'}} >
                    <small style={{fontSize:'12px'}}>
                  If you do not want to continue receiving our commercial communications, you can withdraw your consent by sending an email {configDetails.infoEmail} with the unsubscribe word in the subject.
                  </small>
                  </div>
                  {errorMessage && (
                    <Typography color="error" variant="body2">
                      {errorMessage}
                    </Typography>
                  )}
                   <Button type="submit" variant="contained" id='buttonsubmit' color="primary">
            {t('Submit')}
          </Button>
                  
                </form>
              ) : (
                <Typography variant="h6" color="success.main" style={{padding:'10px'}}>
                  <Trans i18nKey="SuccessMessageContact" components={{ br: <br /> }} /> 
                </Typography>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Contact;
