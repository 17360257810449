import React, { useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import { useTranslation} from 'react-i18next';
import '../components/SearchColumn.css';
import '../components/ContactForm.css';
import './cssMachineryDetails.css';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox'

const SendOfferForm = (props) => {
  const { t } = useTranslation();

  // State to manage form fields
  const [formData, setFormData] = useState({
    priceID: '',
    nameID: '',
    emailID: '',
    telephoneID: '',
    referenceID: props.reference || '',
    companyID: '',
    vatID: '',
    addressCompanyID: '',
  });

  // State to manage file upload
  const [identityFile, setIdentityFile] = useState(null);

  // State to manage submission status
  const [submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Handle input change
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  // Handle file change
  const handleFileChange = (e) => {
    setIdentityFile(e.target.files[0]);
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage('');
    
    const formDataToSubmit = new FormData();
    for (const key in formData) {
      formDataToSubmit.append(key, formData[key]);
    }
    if (identityFile) {
      formDataToSubmit.append('identityFile', identityFile);
    }

    try {
      const response = await fetch('https://quiagromaquinaria.com/apiiiwebsite/save_subbimission.php', {
        method: 'POST',
        body: formDataToSubmit,
      });

      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      const data = await response.json();
      console.log('Form submitted successfully:', data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting form:', error);
      setErrorMessage('Error submitting form. Please try again.');
    }
  };

  return(
    <Box
      component="div"
      sx={{
        '& > :not(style)': { m: 0, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      {!submitted ? (

        
        <form onSubmit={handleSubmit}>
          <div style={{color:'#eabb00', fontWeight:'bold', textAlign:'left', marginLeft:'0px',marginBottom:'10px', marginTop:'10px'}} >Personal information</div>
          <TextField
            fullWidth
            id="nameID"
            label={t('Full Name')}
            variant="outlined"
            required
            value={formData.nameID}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            id="addressID"
            label={t('Address (Street, City, ZIP, Country)')}
            variant="outlined"
            required
            value={formData.addressID}
            onChange={handleChange}
          />
          
          <TextField
            fullWidth
            id="referenceID"
            label="reference"
            variant="outlined"
            required
            type="text"
            style={{display:'none'}}
            value={props.reference}
            onChange={handleChange}
          />
          
          <TextField
            fullWidth
            id="telephoneID"
            label={t('Telephone')}
            variant="outlined"
            required
            value={formData.telephoneID}
            onChange={handleChange}
          />
          <TextField
            fullWidth
            id="emailID"
            label={t('Email')}
            variant="outlined"
            required
            type="email"
            value={formData.emailID}
            onChange={handleChange}
          />

          <div style={{color:'#eabb00', fontWeight:'bold', textAlign:'left', marginLeft:'0px',marginBottom:'10px', marginTop:'10px'}} >Company information</div>
          <div style={{marginLeft:'0px', textAlign:'left', alignContent:'left', marginBottom:'15px'}}> <small>Please note: As a company, you are responsible for indicating your company and VAT. ID</small></div>

          <TextField
            fullWidth
            id="companyID"
            label={t('Company Name')}
            variant="outlined"
            type="text"
            value={formData.companyID}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            id="vatID"
            label={t('VAT ID.')}
            variant="outlined"
            type="text"
            value={formData.vatID}
            onChange={handleChange}
          />

          <TextField
            fullWidth
            id="addressCompanyID"
            label={t('Address (Street, City, ZIP, Country)')}
            variant="outlined"
            type="text"
            value={formData.addressCompanyID}
            onChange={handleChange}
          />

          <div style={{color:'#eabb00', fontWeight:'bold', textAlign:'left', marginLeft:'0px',marginBottom:'10px', marginTop:'10px'}} >Proof of identity</div>
          <div style={{marginLeft:'0px', textAlign:'left', alignContent:'left', marginBottom:'15px'}}>
            <small>ID or Company registration document is required</small>
          </div>

          <div className="custom-file-container">
  <label htmlFor="identityFile" className="custom-file-label">
    {t('Upload')}
  </label>
  <input
    id="identityFile"
    type="file"
    accept="image/*,application/pdf"
    className="custom-file-input"
    onChange={handleFileChange}
  />
  {identityFile && (
  <Typography className="custom-file-name">
    {identityFile.name}
  </Typography>
)}
</div>
<br></br>



          <div className="forceWidth">
            <div className="width100 sizeFont">{t('textFormProduct')}</div>
          </div>
          {errorMessage && (
            <Typography color="error" variant="body2">
              {errorMessage}
            </Typography>
          )}

          <FormGroup style={{alignContent:'center'}}>
            <FormControlLabel required control={<Checkbox />} label="Informed and agreeable" />
          </FormGroup>
          
          <Button type="submit" variant="contained" id='buttonsubmit' color="primary">
            {t('Submit')}
          </Button>
        </form>
      ) : (
        <Typography variant="h6" color="success.main">
          {t('FormSubmitedSuccesfully')}
          <br />
          {t('Succesfully123')}
        </Typography>
      )}
    </Box>
  );
};

export default SendOfferForm;
